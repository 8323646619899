// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-aengusm-gatsby-theme-brain-src-templates-brain-js": () => import("./../node_modules/@aengusm/gatsby-theme-brain/src/templates/brain.js" /* webpackChunkName: "component---node-modules-aengusm-gatsby-theme-brain-src-templates-brain-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-newsletter-mdx": () => import("./../src/pages/newsletter.mdx" /* webpackChunkName: "component---src-pages-newsletter-mdx" */),
  "component---src-pages-vocalchords-privacy-policy-mdx": () => import("./../src/pages/vocalchords-privacy-policy.mdx" /* webpackChunkName: "component---src-pages-vocalchords-privacy-policy-mdx" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

